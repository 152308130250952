/** @format */

import {makeStyles} from '@material-ui/core/styles';

const SubStyles = makeStyles({
  cardInput: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  contBtn: {
    borderRadius: '8px',
    fontSize: '6rem!important',
    padding: '2rem 0',
  },
  label: {
    color: '#666',
    fontSize: '1.75rem',
    marginBottom: '1rem',
    width: '100%',
    '&.no-lbl': {
      marginBottom: 0,
    },
  },
  addressWrapper: {
    paddingTop:20,
    fontSize:'6rem',
  },
  buttonWrapper : {
    fontSize:'6rem',
    '& .MuiButton-label': {
      fontSize:'6rem'
    }
  },
  pgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight:'75vh'
  },
  submitBtn: {
    marginBottom: '2rem',
    marginTop: '7.75rem',
  },
});

export default SubStyles;
