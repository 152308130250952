const settings = `-s initial_extruder_nr=0 -s layer_height= -s wall_extruder_nr="-1" -s infill_pattern=grid -s infill_sparse_density= -s cross_infill_pocket_size= -s infill_extruder_nr="-1" -s support_infill_extruder_nr=0 -e0 -s infill_sparse_density= -s infill_sparse_thickness=`;

const overrides = (layerHeight,infill,type='local') => {
    let maxInfill = infill;
    if (Number(infill) === 100) {
        maxInfill = 99;
    }
    let line_distance = (
        ((10 - layerHeight) / 100) *
        (100 - maxInfill)
      ).toFixed(2);
    const tmp =  [
      {
        key: 'initial_extruder_nr',
        value: 0
      },
      {
        key: 'layer_height',
        value: layerHeight
      },
      {
        key: 'infill_pattern',
        value: infill>25?'lines':'grid'
      },
      {
        key: 'infill_sparse_density',
        value: maxInfill
      },
      {
        key:'cross_infill_pocket_size',
        value:line_distance
      },
      {
        scope: 'e0',
        key: 'infill_sparse_density',
        value: maxInfill
      },
      {
        scope: 'e0',
        key: 'infill_sparse_thickness',
        value: layerHeight
      },
      {
        scope: 'e0',
        key: 'infill_line_distance',
        value: line_distance
      },
      {
        scope: 'e0',
        key: 'cross_infill_pocket_size',
        value: line_distance
      },
      {scope: 'e0',
        key: 'support_enable',
        value: true}
    ]

    if(type === 'api') {
      let tmp2 = {};
      tmp.forEach(item=>{
        tmp2[item.key] = item.value;
      });
      return tmp2;
    } else {
      tmp.push({
          scope: 'e0',
          key: 'support_enable',
          value: true
      })
      return tmp;
    }
  
  };

export {settings, overrides}