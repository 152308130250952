/** @format */

import React, {useEffect, useState} from 'react';
// Custom components
import {Link} from 'react-router-dom';
// Material UI
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/styles';
// Material UI Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Custom Components
import PaymentSourceElement from '../prefilledPaymentDetails/paymentSourceElement';
import PrefilledShippingDetails from '../prefilledShippingDetails/subShippingDetails';

import { Button, Grid, Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Prompt from '../../components/prompt';
import { useSnackbar } from 'notistack';
import {CheckboxStyles} from './styles';
//#region Styles

const ActiveSubTxt = styled(Typography)(
  {
    color: props => (props.isactive === 'true' ? 'green' : 'red'),
  },
  {name: 'active-sub-txt'}
);

const CollapseHeader = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 'max-content',
    justifyContent: 'space-between',
    margin: '0',
    width: '100%',
  },
  {name: 'collapse-header'}
);

const EditPaymentBtn = styled(ButtonBase)(
  {
    borderRadius: '8px',
    color: '#0062ff',
    padding: '1rem 2rem',
    position: 'relative',
  },
  {name: 'edit-pay-btn'}
);

const ExpandBtn = styled(ButtonBase)(
  {
    borderRadius: '8px',
    color: '#8a8a8a',
    height: '100%',
    padding: '1rem 2rem',
    position: 'relative',
    right: '-2rem',
    '&.MuiIconButton-label': {
      maxHeight: '100%',
    },
    '& .txt': {
      saturation: '80%',
    },
  },
  {name: 'expand-btn'}
);

const StyledExpandIcon = styled(ExpandMoreIcon)(
  {
    color: 'initial',
    marginLeft: '1rem',
    '&.isOpen': {
      transform: 'rotate(180deg)',
    },
    '&.loading': {
      visibility: 'hidden',
    },
  },
  {name: 'styled-expand-icon'}
);

const StyledTxt = styled(Typography)(
  {
    '& .date-span': {
      justifySelf: 'right',
    },
    '&.indent': {
      marginLeft: '1rem',
    },
    '&.info-wrapper': {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    '&.subheader': {
      margin: '2rem 0 1rem',
    },
  },
  {name: 'subs-styled-txt'}
);

const SubHeaderRow = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  {name: 'subhead-row'}
);

const SubInfoWrapper = styled('div')(
  {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  {name: 'sub-info-wrap'}
);

const StyledCheckbox = styled(Checkbox)(
  {
    color:'#000'
  },
  {name: 'styled-checkbox'}
);


const Wrapper = styled('div')({}, {name: 'subs-wrap'});

//#endregion

export default function Subscriptions(props) {
  // Maybe used when implementing multiple subscriptions?
  // const [userSubs, setUserSubs] = useState([]);
  const {paymentSources = [],userData} = props;
  const [pbSub, setPbSub] = useState(null);
  const [fullCardObj, setFullCardObj] = useState(null);
  const [isFullyLoaded, setIsFullyLoaded] = useState(true);
  const [opts,setOpts] = useState({open:false});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = () => {
    fetch(`/api/subscription`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(resp => resp.json())
      .then(result => {
        // console.log('result: ', result);
        if (result && result.id) {
          setSubObj(result);
        }
      });
  }

  const setSubObj = (result) => {
    const subId = result.id;
    const paymentObj = result.payment;
    const subStatus = result.valid;
    const paymentType = result.paymentType;
    const paymentAddr = result.address;
    const shippingAddress = result.address?.address;
    const startDate = new Date(result.start);
    const endDate = new Date(result.end);
    const subActive = result.valid;
    let subObj = {
      id: subId,
      payment: paymentObj,
      status: subStatus,
      paymentAddress: paymentAddr,
      paymentType: paymentType,
      startDate: startDate,
      endDate: endDate,
      isActive: subActive,
      shippingAddress: shippingAddress,
    };
    setPbSub(subObj);
  }

  const shippingAddress = {
    address:pbSub?.shippingAddress.line1,
    address2:pbSub?.shippingAddress.line2,
    city:pbSub?.shippingAddress.city,
    country:pbSub?.shippingAddress.country,
    email:pbSub?.paymentAddress.email,
    firstName:pbSub?.paymentAddress.firstName,
    lastName:pbSub?.paymentAddress.lastName,
    phone:pbSub?.paymentAddress.phone,
    zipCode:pbSub?.shippingAddress.postal_code,
  }

  // useEffect(() => {
  //   if (pbSub && paymentSources.length > 0) {
  //     let fullCardObj = paymentSources.find(
  //       c => c.card.fingerprint === pbSub.payment.card.fingerprint
  //     );
  //     if (fullCardObj) {
  //       fullCardObj.card.name = fullCardObj.owner.name;
  //       setFullCardObj(fullCardObj);
  //       if (pbSub.payment.card.name === null) {
  //         let tempSubObj = pbSub;
  //         tempSubObj.payment.card.name = fullCardObj.card.name;
  //         setPbSub(tempSubObj);
  //       }
  //     }
  //     if (pbSub.payment.card.name === null) {
  //       let tempSubObj = pbSub;
  //       tempSubObj.payment.card.name = fullCardObj
  //         ? fullCardObj.card.name
  //         : ' ';
  //       setPbSub(tempSubObj);
  //     }
  //   }
  // }, [pbSub, paymentSources]);

  useEffect(() => {
    if (paymentSources.length > 0) {
      if (pbSub?.payment?.card) {
        if (pbSub.payment.card.name !== null) {
          setIsFullyLoaded(true);
        }
      }
    }
  }, [pbSub, paymentSources, fullCardObj]);

  const changeLocalPickup = (e) => {
    if(pbSub.paymentAddress.serviceCode !==0) {
      setOpts({open:true,title:"Change Subscription to Local Pickup?", msg:"Are you sure you want to change your subscription to be local pickup?", onConfirm: confirmedLocal(0), onCancel:()=>setOpts({open:false})})
    } else {
      enqueueSnackbar(`Please edit the address to change to shipping.`,{variant:'info'});
    }
  }

  const confirmedLocal = serviceCode => () => {

    const body = {
      customerInfo:{
        serviceCode
      }
    };

    fetch(`/api/subscription`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body:JSON.stringify(body)
    })
      .then(resp => resp.json())
      .then(result => {
        setSubObj(result);
        setOpts({open:false});
      });
  }
  return (
    <Wrapper>
      <Prompt {...opts}/>
      <Grid container>
        <Grid item md={6}>
          <SubInfoCard
            fullyLoaded={isFullyLoaded}
            selectedCardObj={fullCardObj}
            subObj={pbSub}
            userData={userData}
            subTitle="Printbed Premium"
            getSubscription={getSubscription}
          />
          
        </Grid>
        <Grid item md={6}>
          {shippingAddress.address && <PrefilledShippingDetails
            customerInfo={shippingAddress}
            userId={userData.userId}
            // ref={shippingDetailsRef}
            onChange={setSubObj}
            isFormOnSide={true}
          />}
          {pbSub?.paymentAddress?.serviceCode===0?
          <><Typography>Local Pickup* </Typography><Typography variant="caption">*Edit address to change to shipping.</Typography></>
          :
          <Button color="primary" variant="contained" onClick={changeLocalPickup} style={{marginTop:0}}>Change to Local Pickup.</Button>
          }
        </Grid>
      </Grid>
    </Wrapper>
  );
}

function SubInfoCard(props) {
    const { enqueueSnackbar } = useSnackbar();
  const {
    fullyLoaded = false,
    selectedCardObj = {},
    subObj,
    subTitle = '',
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [opts,setOpts] = useState({open:false});
  const cancelSub = () => {

    setOpts({open:true,title:"Are You sure?", msg:"Are you sure you want to cancel your subscription?", onConfirm: confirmedCancel, onCancel:()=>setOpts({open:false})})
    
  };

  const confirmedCancel = () => {
    setOpts({open:false});
    fetch(`/api/subscription`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
      })
        .then(resp => resp.json())
        .then(result => {
          if (result.membershipValidUntil) {
            //membershipValidUntil
            enqueueSnackbar(`We're sorry to see you go. Your membership will remain active until ${new Date(result.membershipValidUntil).toDateString().slice(4)}.`,{variant:'info'});
          } else {
            enqueueSnackbar(`We're sorry but there was an issue canceling your subscription please contact support via email support@printbed.com`,{variant:'error'});
          }
          props.getSubscription();
        });
  }

  return subObj ? (
    <SubInfoWrapper>
      <Prompt {...opts}/>
      <StyledTxt variant="h4">{subTitle}</StyledTxt>
      <CollapseHeader>
        <StyledTxt>
          Status:&nbsp;
          <ActiveSubTxt component="span" isactive={subObj.isActive.toString()}>
            {subObj.paymentType === 'stripe' && subObj.payment.status.toTitleCase()}
            {subObj.paymentType === 'paypal' && subObj.payment.status.toTitleCase()}
            {/* {subObj.payment.status ? subObj.isActive ? 'Active' : 'Inactive':subObj.payment.status} */}
          </ActiveSubTxt>
        </StyledTxt>
        {subObj.id && 
        <ExpandBtn
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <span className="txt">
            {fullyLoaded ? (isOpen ? 'Less Info' : 'More Info') : 'Loading...'}
          </span>
          <StyledExpandIcon
            className={fullyLoaded ? (isOpen ? 'isOpen' : '') : 'loading'}
          />
        </ExpandBtn>}
      </CollapseHeader>
      <Collapse in={isOpen} timeout="auto">
        <StyledTxt className="info-wrapper">
          Initial Subscription Date:&nbsp;
          <span className="date-span">
            {subObj.startDate.toDateString().slice(4)}
          </span>
        </StyledTxt>
        <StyledTxt className="info-wrapper">
          Current bill cycle end:&nbsp;
          <span className="date-span">
            {subObj.endDate.toDateString().slice(4)}
          </span>
        </StyledTxt>
        <Button variant="contained" onClick={cancelSub} style={{marginTop:50}}>Cancel My subscription.</Button>
      </Collapse>
    </SubInfoWrapper>
  ) : <div>You have no Subscriptions.</div>;
}

//#region ChangePaymentComp styled elements

const PaymentCompWrapper = styled('div')(
  {
    alignItems: 'normal',
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    padding: '0',
  },
  {name: 'payment-comp-wrap'}
);

//#endregion

function ChangePaymentComp(props) {
  const {paymentObj = {}, selectedCardObj = {}} = props;
  const [showAllPaymentOpts, setShowAllPaymentOpts] = useState(false);

  function toggleAllPaymentMethods() {
    setShowAllPaymentOpts(!showAllPaymentOpts);
  }

  return (
    <PaymentCompWrapper>
      <SubHeaderRow>
        <StyledTxt className="subheader" variant="h5">
          Payment Method
        </StyledTxt>
        <EditPaymentBtn
          disableRipple
          onClick={() => toggleAllPaymentMethods()}
          variant="text">
          {showAllPaymentOpts ? 'Cancel' : 'Change'}
        </EditPaymentBtn>
      </SubHeaderRow>
      <PaymentSourceElement
        isSelected={
          paymentObj?.card?.fingerprint === selectedCardObj?.card?.fingerprint
        }
        paymentSourceInfo={paymentObj}
        showDeleteBtn={false}
        showSelectedIcon={showAllPaymentOpts}
      />
    </PaymentCompWrapper>
  );
}

function PaypalPaymentComp(props) {
  return <></>;
}
