/** @format */

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {detect} from 'detect-browser';
// Material UI Components
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
// ************* Unused but kept for future use *************
//import Rating from '@material-ui/lab/Rating';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// Feather icons
import {ArrowLeft, ShoppingCart} from 'react-feather';
// Custom imports
import {SirvViewer} from './image-viewer.js';
import ItemStyles from './styles';
import QtySelect from './select';
import DescModalBtn from './desc-modal';
import SubmitButton from '../styled/submit-button.js';
import {useEffect} from 'react';

const TAG_WHITELIST = ['color', 'diameter', 'weight'];
// let QTY_LIMIT_ARRAY = Array(20).fill(0);
let docHeight = 0;
if (typeof window === 'undefined') {
  global.window = {};
}
try {
  docHeight = document.documentElement.clientHeight;
} catch (e) {}
const vh = Math.round(
  (Math.max(docHeight, window.innerHeight || 0) / 100) *
    5 *
    window.devicePixelRatio
);

export default function Item(props) {
  const BROWSER = detect();
  const pgStyles = ItemStyles();
  const {
    itemId,
    options,
    relatedItems,
    cartUtils,
    userData,
    isSpool,
    spoolOutOfStock,
    selectedItem: currentItem,
  } = props;
  const history = useHistory();
  const {addItemToCart = () => {}} = cartUtils || {};
  const [quantity, setQuantity] = useState(1);
  const [plasticSpool,setPlasticSpool] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultOptions || {}
  );
  const [QTY_LIMIT_ARRAY, setQTY_LIMIT_ARRAY] = useState([...Array(20).keys()]);
  const {membership} = userData;
  const optSet = new Set();
  const qtyMap = {};
  //#region Initialization
  // Populating optSet
  relatedItems.forEach(i => {
    let setKey = '';
    for (const tag of TAG_WHITELIST) {
      setKey += i.tags[tag].toLowerCase();
    }
    qtyMap[i.tags.color.toLowerCase()] = i.qoh;
    optSet.add(setKey);
  });

  //#endregion

  const handleOptionSelect = (option, choice) => {
    const newOptions = {...selectedOptions};
    newOptions[option] = choice;
    if (option === 'color') {
      newOptions['colorName'] =
        options.colorName[options.color.indexOf(choice)];
    }
    setSelectedOptions(newOptions);
    let searchString = '?';
    let newUrl = `/shop/${newOptions.material}/${newOptions.color}-${newOptions.diameter}-${newOptions.weight}`;

    history.replace(newUrl, {
      newOptions: newOptions,
      pathname: newUrl,
    });
  };
  useEffect(() => {
    if (
      currentItem.tags.color === 'Leftovers' &&
      currentItem.tags.material.indexOf('Mini') < 0
    ) {
      setQTY_LIMIT_ARRAY(QTY_LIMIT_ARRAY.filter(e => e % 2 === 0));
      setQuantity(2);
    } else {
      setQTY_LIMIT_ARRAY([...Array(20).keys()]);
      setQuantity(1);
    }
  }, [currentItem]);

  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];
  return (
    <div
      className={clsx(
        pgStyles.wrapper,
        browserPadding.includes(BROWSER) ? 'safari-pad' : ''
      )}
      key={'item-wrapper-' + itemId}>
      <IconButton
        className={pgStyles.backArrow}
        onClick={() => {
          history.goBack();
        }}>
        <ArrowLeft color="#3d3d3d" />
      </IconButton>
      <div className={pgStyles.imgs}>
        <SirvViewer currentItem={currentItem} isSpool={isSpool}/>
        {/* <ImageViewer currentItem={currentItem} className={pgStyles.imgViewer} /> */}
      </div>
      <Paper elevation={0} className={pgStyles.details}>
        <div className={pgStyles.topTitles}>
          <div className={pgStyles.titles}>
            <Typography variant="h1" className="other">
              {`${currentItem.tags.colorName} ${currentItem.tags.material} ${currentItem.tags.weight} ${currentItem.tags.diameter}`}
            </Typography>
            {currentItem.tags.material === 'PLA Sub' &&
              membership === false && (
                <Typography variant="h5" className={pgStyles.titles}>
                  Premium Exclusive
                </Typography>
              )}
          </div>
          <Typography variant="h5" className={pgStyles.titles}>
            ${(currentItem.price / 100.0).toFixed(2)}
          </Typography>
        </div>
        <div>
          {Object.entries(options)
            .filter(opt => TAG_WHITELIST.includes(opt[0]))
            .map((option, index, array) => {
              return (
                <div key={option + index}>
                  {option[0] !== 'color' ? (
                    <Typography
                      variant="body1"
                      className={pgStyles.optTitle}
                      key={option[0] + '-title'}>
                      {option[0]}
                    </Typography>
                  ) : null}
                  <List key={option[0]} className={pgStyles.list}>
                    <div
                      className={
                        pgStyles.optionWrapper +
                        (index === array.length - 1 ? ' last' : '')
                      }>
                      {/* <Typography variant='body1' ></Typography> */}
                      {option[1].map((choice, index) => {
                        const isSelected =
                          selectedOptions[option[0]].toLowerCase() ===
                          choice.toLowerCase();
                        let setComparatorKey = '';
                        for (const tag of TAG_WHITELIST) {
                          if (tag === option[0]) {
                            setComparatorKey += choice.toLowerCase();
                          } else {
                            setComparatorKey +=
                              selectedOptions[tag].toLowerCase();
                          }
                        }
                        const isDisabled = !optSet.has(setComparatorKey) || qtyMap[choice.toLowerCase()]<=0;
                        return (
                          <Tooltip
                            key={choice + '-' + index}
                            className={
                              pgStyles.optionContainer +
                              (isSelected ? ' selected' : '')
                            }
                            disableHoverListener={isDisabled}
                            disableTouchListener={isDisabled}
                            disableFocusListener={isDisabled}
                            title={choice}
                            placement="top">
                            <span>
                              {option[0] === 'color' ? (
                                <Button
                                  className={pgStyles.optionChoice + ' color'}
                                  onClick={() => {
                                    handleOptionSelect(option[0], choice);
                                  }}
                                  style={{padding: '.7rem'}}
                                  disabled={isDisabled}>
                                  <div
                                    className="color-div"
                                    style={{
                                      background: `url('https://cdn.sunsh1n3.com/Images/printbed/${encodeURIComponent(
                                        choice.replace("'", '')
                                      )}.jpg?w=${vh}') no-repeat`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      height: '105%',
                                    }}>
                                    &nbsp;
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  className={pgStyles.optionChoice}
                                  onClick={() =>
                                    handleOptionSelect(option[0], choice)
                                  }
                                  disabled={isDisabled}>
                                  {choice}
                                </Button>
                              )}
                            </span>
                          </Tooltip>
                        );
                      })}
                    </div>
                  </List>
                </div>
              );
            })}
        </div>
        <QtySelect
          vals={QTY_LIMIT_ARRAY}
          curVal={quantity}
          onSelect={e => setQuantity(e)}
        />
        {/* {isSpool &&
            <FormControlLabel
              className={pgStyles.checkBoxLabel}
              control={
                <Checkbox
                  checked={plasticSpool}
                  onChange={(e)=>{
                    setPlasticSpool(e.target.checked);
                  }}
                  size="medium"
                  name="plasticSpool"
                  color="primary"
                  disabled={spoolOutOfStock}
                  className={pgStyles.checkBox}
                />
              }
              label={spoolOutOfStock?<span>Ship with Plastic Spool <sup style={{color:'Red', fontSize:'4rem'}}>* Out of stock</sup></span>:"Ship with Plastic Spool"}
            />} */}
        <DescModalBtn desc={currentItem.desc} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}>
          {currentItem.tags.material === 'PLA Sub' && membership === false ? (
            <Button
              className={pgStyles.addToCartButton}
              onClick={() => history.push('/premium')}>
              Join Premium
            </Button>
          ) : (
            <SubmitButton
              className={pgStyles.addToCartButton}
              disabled={!(currentItem.qoh > 0)}
              endIcon={<ShoppingCart size={16} />}
              onClick={e => {
                e.preventDefault();
                let tmp = {
                  id: currentItem.id,
                  quantity: parseInt(quantity),
                  options: selectedOptions,
                };
                if (currentItem.sides) {
                  tmp.sides = currentItem.sides;
                }
                addItemToCart(tmp);
              }}
              variant="contained">
              Add to Cart
            </SubmitButton>
          )}
        </div>
      </Paper>
    </div>
  );
}
